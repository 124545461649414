<template>
    <div class="hello">
      <!-- Your location data is {{ location.coords.latitude }}, {{ location.coords.longitude}} -->
      <div id="map"></div>
    </div>
  </template>
  <script src="https://api.tiles.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.js"></script>
  <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
  <script>
  import Vue from "vue";
  import mapboxgl from "mapbox-gl";
  import Myicon from "../assets/gps.jpg";
//   import VuePrompt from 'vue-prompt';

//   Vue.use(VuePrompt);
  export default {
    name: "Goooglemap",
    props: [
      "name",
      "route",
      "stopList",
      "AddRoutesaving",
      "currentloc",
      "editedIndex"
    ],
    data() {
      return {
        user: JSON.parse(localStorage.getItem("alcotrackerparentuser")),
        // mapMarker: "",
        // mapName: this.name || "routemap2",
        map: null,
        // bounds: null,
        markers: []
        // inputValue: ""
      };
    },
    methods: {
      placeMarker(location, content) {
        var print =
          "<b>" +
          "Stopname" +
          ":</b>&nbsp;" +
          content +
          "<br/><b>" +
          "Stopposition" +
          ":</b>&nbsp;" +
          this.markers.length;
        this.markers.push({
          type: "Feature",
          properties: {
            description: print
            // icon: "art-gallery-15"
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(location.lng.toFixed(3)),
              parseFloat(location.lat.toFixed(3))
            ]
          }
        });
        this.mapCanvas.loadImage(Myicon, (error, image) => {
          if (error) throw error;
          this.mapCanvas.addImage("cat", image);
  
          if (!this.mapCanvas.getSource("places")) {
            this.mapCanvas.addSource("places", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: this.markers
              }
            });
            this.mapCanvas.addLayer({
              id: "places",
              type: "symbol",
              source: "places",
              layout: {
                "icon-image": "cat",
                // "icon-allow-overlap": true
                "icon-size": 0.25
              }
            });
          } else {
            this.mapCanvas.getSource("places").setData({
              type: "FeatureCollection",
              features: this.markers
            });
            this.mapCanvas.addLayer({
              id: "places",
              type: "symbol",
              source: "places",
              layout: {
                "icon-image": "cat",
                // "icon-allow-overlap": true
                "icon-size": 0.25
              }
            });
          }
        });
      },
      addStop(latLng) {
        console.log(latLng)
        const stopName = prompt("Enter Stop Name:");
        if (stopName) {
            console.log(stopName);
            // Assuming this.$emit and placeMarker are defined elsewhere
            this.$emit("mapOnClick", latLng, stopName);
            this.placeMarker(latLng, stopName);
      }
      },
      DeleteMarker(marker) {
        this.$confirm({
          title: "Do you want to delete marker?",
          acceptText: "Delete",
          cancelText: "Cancel"
        })
          .then(result => {
            if (result) {
              var lat1, lng1;
              lat1 = marker._lngLat.lat;
              lng1 = marker._lngLat.lng;
              var mark = {
                lat: lat1,
                lon: lng1
              };
              this.markers.map((res, index) => {
                var lat2 = res.geometry.coordinates[1];
                var lng2 = res.geometry.coordinates[0];
                if (lat1 == lat2 && lng1 == lng2) {
                  this.$emit("removeMarker", mark);
                  if (this.editIndex != -1) {
                    Vue.axios
                      .get(
                        "https://robopower.xyz/app/routes/removestop?routeId=" +
                          this.route +
                          "&stopid=" +
                          index
                      )
                      .catch(error => {
                        console.log("Error: ", error);
                      });
                  }
                  this.markers.splice(index, 1);
                  if (!this.mapCanvas.getSource("places")) {
                    this.mapCanvas.addSource("places", {
                      type: "geojson",
                      data: {
                        type: "FeatureCollection",
                        features: this.markers
                      }
                    });
                    // Add a layer showing the places.
                    this.mapCanvas.addLayer({
                      id: "places",
                      type: "symbol",
                      source: "places",
                      layout: {
                        "icon-image": "{icon}",
                        "icon-allow-overlap": true
                      }
                    });
                  } else {
                    this.mapCanvas.getSource("places").setData({
                      type: "FeatureCollection",
                      features: this.markers
                    });
                    // Add a layer showing the places.
                    this.mapCanvas.addLayer({
                      id: "places",
                      type: "symbol",
                      source: "places",
                      layout: {
                        "icon-image": "{icon}",
                        "icon-allow-overlap": true
                      }
                    });
                  }
                }
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      init() {
        mapboxgl.accessToken =
          "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
        this.mapCanvas = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: [78.04, 17.907],
          zoom: 11.15
        });
        if (this.mapCanvas) return Promise.resolve(this);
        else return Promise.reject(this);
      },
      onloader() {
        this.init()
          .then(function(vm) {
            vm.mapCanvas.on("click", e => {
              if (vm.mapCanvas.getCanvas().style.cursor != "pointer")
                vm.addStop(e.lngLat);
            });
            // }
            vm.mapCanvas.on("load", () => {
              vm.mapCanvas.on("click", "places", e => {
                const coordinates = e.features[0].geometry.coordinates;
                const description = e.features[0].properties.description;
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                var pointer = new mapboxgl.Popup()
                  .setLngLat([
                    coordinates[0].toFixed(3),
                    coordinates[1].toFixed(3)
                  ])
                  .setHTML(description);
                // .addTo(vm.mapCanvas);
                vm.DeleteMarker(pointer);
              });
            });
            vm.mapCanvas.on("mouseenter", "places", e => {
              vm.mapCanvas.getCanvas().style.cursor = "pointer";
              const coordinates = e.features[0].geometry.coordinates.slice();
              const description = e.features[0].properties.description;
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }
  
              new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(description)
                .addTo(vm.mapCanvas);
            });
  
            // Change it back to a pointer when it leaves.
            vm.mapCanvas.on("mouseleave", "places", () => {
              vm.mapCanvas.getCanvas().style.cursor = "";
            });
            vm.mapCanvas.resize();
          })
          .catch(function() {
            console.error("Map Initialization FAILED");
          });
        this.stopList.map(stop => {
          var print =
            "<b>" +
            "Stopname" +
            ":</b>&nbsp;" +
            stop.stopname +
            "<br/><b>" +
            "Stopposition" +
            ":</b>&nbsp;" +
            stop.stopid;
          this.markers.push({
            type: "Feature",
            properties: {
              description: print
              // icon: "art-gallery-15"
            },
            geometry: {
              type: "Point",
              coordinates: [
                parseFloat(stop.lon).toFixed(3),
                parseFloat(stop.lat).toFixed(3)
              ]
            }
          });
        });
        this.mapCanvas.loadImage(Myicon, (error, image) => {
          if (error) throw error;
          this.mapCanvas.addImage("cat", image);
  
          if (!this.mapCanvas.getSource("places")) {
            this.mapCanvas.addSource("places", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: this.markers
              }
            });
            this.mapCanvas.addLayer({
              id: "places",
              type: "symbol",
              source: "places",
              layout: {
                "icon-image": "cat",
                // "icon-allow-overlap": true
                "icon-size": 0.25
              }
            });
          } else {
            this.mapCanvas.getSource("places").setData({
              type: "FeatureCollection",
              features: this.markers
            });
            this.mapCanvas.addLayer({
              id: "places",
              type: "symbol",
              source: "places",
              layout: {
                "icon-image": "cat",
                // "icon-allow-overlap": true
                "icon-size": 0.25
              }
            });
          }
        });
      }
    },
  
    beforeMount() {
      this.user = JSON.parse(
        localStorage.getItem("alcotrackerparentuser")
      );
    },
    mounted: function() {
      this.onloader();
    }
  };
  </script>
  <style scoped>
  #map {
    height: 300px;
    width: 100%;
  }
  </style>
  